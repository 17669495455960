import { Button } from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx';
import { AiOutlineArrowRight, AiOutlineCloseSquare } from 'react-icons/ai';
import whitehorseLogo from '../assets/logo-updated.png';
import { AppRoutes } from '../types';

export default function LoginSignupHeader() {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    // Cleanup effect
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [showMenu]);

  return (
    <div className="sticky top-0 z-50 bg-white flex items-center justify-between">
      <button className="flex items-center" onClick={() => navigate('/')}>
        <div className="py-4 px-2 md:px-4">
          <img
            src={whitehorseLogo}
            alt="whitehorse"
            className="xl:h-full xl:w-full"
          />
        </div>
        <div className="header-font whitehorse-text-gradient text-base md:text-4xl text-primary-v1 xl:text-3xl">
          WhiteHorse
        </div>
      </button>
      <button
        aria-label="toggle"
        className="mr-8 xl:hidden"
        onClick={toggleMenu}
      >
        <RxHamburgerMenu className="text-4xl md:text-5xl text-black" />
      </button>
      {showMenu && (
        <div className="absolute modal-z-index inset-0 min-h-screen w-full flex bg-white">
          <div className="w-1/5 bg-black opacity-75" />
          <div className="w-4/5">
            <div className="flex text-black mt-2">
              <div className="flex-1 flex flex-col mr-8">
                <div className="flex-1 flex justify-end">
                  <button onClick={toggleMenu} aria-label="toggle">
                    <AiOutlineCloseSquare className="text-4xl md:text-5xl text-black" />
                  </button>
                </div>
                <button
                  className="flex-1 py-4 pl-4 border-b text-left text-primary-v1"
                  onClick={() => {
                    navigate('/');
                    toggleMenu();
                  }}
                >
                  Home
                </button>
                <button
                  className="flex-1 py-4 pl-4 border-b text-left text-primary-v1"
                  onClick={() => {
                    navigate(`${AppRoutes.SIGNUP}?userRole=COMPANY`);
                    toggleMenu();
                  }}
                >
                  Pitch your Startup
                </button>
                <button
                  className="flex-1 py-4 pl-4 border-b text-left text-primary-v1"
                  onClick={() => {
                    navigate(`${AppRoutes.SIGNUP}?userRole=INVESTOR`);
                    toggleMenu();
                  }}
                >
                  Start Investing
                </button>
                <button
                  className="flex-1 py-4 pl-4 border-b text-left text-primary-v1"
                  onClick={() => {
                    navigate(`${AppRoutes.SIGNUP}?userRole=ADVISOR_OR_COACH`);
                    toggleMenu();
                  }}
                >
                  Become an Advisor
                </button>
                <div className="pt-4 flex">
                  <button
                    className="button-secondary flex justify-between items-center mx-4"
                    type="button"
                    onClick={() => {
                      navigate(AppRoutes.LOGIN);
                      toggleMenu();
                    }}
                  >
                    <div className="mx-4 px-1">Sign In</div>
                    <div className="text-xl mr-4">
                      <AiOutlineArrowRight />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="hidden xl:flex justify-end mr-8 p-4">
        <Button
          onClick={() => {
            navigate(`${AppRoutes.SIGNUP}?userRole=COMPANY`);
          }}
          className="text-black border-none text-lg p-6 flex-center mr-4"
        >
          Pitch your Startup
        </Button>
        <Button
          onClick={() => {
            navigate(`${AppRoutes.SIGNUP}?userRole=INVESTOR`);
          }}
          className="text-black border-none text-lg p-6 flex-center mr-4"
        >
          Start Investing
        </Button>
        <Button
          onClick={() => {
            navigate(`${AppRoutes.SIGNUP}?userRole=ADVISOR_OR_COACH`);
          }}
          className="text-black border-none text-lg p-6 flex-center mr-4"
        >
          Become an Advisor
        </Button>
        <Button
          onClick={() => {
            navigate(AppRoutes.SIGNUP);
          }}
          className="bg-white border-primary-v1 text-primary-v1 opacity-90 rounded-3xl font-medium text-lg p-6 flex-center mr-4"
        >
          Sign Up
        </Button>
        <Button
          className="bg-white border-primary-v1 text-primary-v1 opacity-90 rounded-3xl font-medium text-lg p-6 flex-center"
          onClick={() => {
            navigate(AppRoutes.LOGIN);
          }}
        >
          Sign In
        </Button>
      </div>
    </div>
  );
}
