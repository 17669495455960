import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SharedClientJwtObject,
  SharedUserJourneyType,
} from '@shared/api-responses';

interface IUserState {
  data: SharedClientJwtObject | null;
  journey: (SharedUserJourneyType & { userBypassed: boolean }) | null;
  isAdmin: boolean;
  selectedCompanyId: string;
  selectedBucketId: string;
  toggledAdvisor: boolean;
  fromInvestments: boolean;
}

const initialState: IUserState = {
  data: null,
  journey: null,
  isAdmin: false,
  selectedCompanyId: '',
  selectedBucketId: '',
  toggledAdvisor: false,
  fromInvestments: false,
};

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    logout: () => initialState,
    setUser: (state, action: PayloadAction<SharedClientJwtObject>) => {
      state.data = action.payload;
    },
    setUserMetadata: (state, action: PayloadAction<SharedUserJourneyType>) => {
      state.journey = { ...action.payload, userBypassed: false };
    },
    setUserOverride: (state) => {
      if (state.journey) {
        state.journey.userBypassed = true;
      }
    },
    setUserAsAdmin: (state) => {
      state.isAdmin = true;
    },
    setUserCurrentStep: (state, action: PayloadAction<number>) => {
      if (state.journey) {
        state.journey.currentStep = action.payload;
      }
    },
    setSelectedCompanyId: (state, action: PayloadAction<string>) => {
      state.selectedCompanyId = action.payload;
    },
    setSelectedBucketId: (state, action: PayloadAction<string>) => {
      state.selectedBucketId = action.payload;
    },
    setToggledAdvisor: (state, action: PayloadAction<boolean>) => {
      state.toggledAdvisor = action.payload;
    },
    setFromInvestments: (state, action: PayloadAction<boolean>) => {
      state.fromInvestments = action.payload;
    },
  },
});

export default userSlice.reducer;

export const {
  logout,
  setUser,
  setUserMetadata,
  setUserCurrentStep,
  setUserAsAdmin,
  setSelectedCompanyId,
  setSelectedBucketId,
  setToggledAdvisor,
  setFromInvestments,
} = userSlice.actions;
