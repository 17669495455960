import { SharedEndUserSignupInputType } from '@shared/api-inputs';
import { SharedClientJwtObject } from '@shared/api-responses';
import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/es/table/interface';

export interface GenericResponse {
  status: string;
  message: string;
}

export const StateQuery = {
  countryId: 0,
  configValue: 0,
};

// Calculate the minimum birthdate for someone who is 18 years old
const eighteenYearsAgo = new Date();
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
export const MinimumDobDate = eighteenYearsAgo;

type RegexValidatorKey = 'phone' | 'currency';
export const FormRegexes: Record<
  RegexValidatorKey,
  { expression: RegExp; message: string }
> = {
  phone: {
    expression: /^(\+\d{1,4}-\d{10})$/,
    message:
      'Please enter a valid phone number in the format: +1-1234567890 (for US) or +91-9876543210 (for India).',
  },
  currency: {
    expression: /^[0-9]*$/,
    message: 'Please enter only numbers here.',
  },
};

type DateValidationKey =
  | 'minimum18YearsCheck'
  | 'minimumCurrentDateCheck'
  | 'maximumCurrentDateCheck';
export const DateValidations: Record<
  DateValidationKey,
  (current: Date) => boolean
> = {
  minimum18YearsCheck: (current: Date) =>
    current && current >= eighteenYearsAgo,
  minimumCurrentDateCheck: (current: Date) => current && current < new Date(),
  maximumCurrentDateCheck: (current: Date) => current && current > new Date(),
};

export interface IResetPasswordRequest {
  resetToken: string;
  password: string;
  passwordConfirm: string;
}

export type UserJwtObjectType = SharedClientJwtObject;

export type UserRegisterInputType = SharedEndUserSignupInputType;

export type LoginInput = {
  email: string;
  secret: string;
  forAdmin: boolean;
};

export type WhitehorseTableParams = {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
};

export const PaginationParams = {
  current: 1,
  pageSize: 10,
} as const;

export enum AppRoutes {
  HOME = '/',
  CLIENT_ADMIN_LOGIN = '/admin',
  ADMIN_DASHBORD = '/dashboard-admin',
  DASHBOARD = '/dashboard',
  LOGIN = '/login',
  SIGNUP = '/signup',
  VERIFY = '/verify',
  RESET = '/reset',
  TERMS = '/terms-of-use',
  ERROR = '/not-found',
  ONBOARDING = '/onboarding',
  UNAUTHORIZED = '/unauthorized',
  DETAILED_COMPANY_PAGE = '/details',
  PAYMENTS = '/payments',
  ADMIN_COMPANY_PAGE = '/startup',
  REFERRAL_DASHBOARD = 'referrals',
  ADMIN_DETAILED_COMPANY_PAGE = '/startup-details',
  ADMIN_ADVISORS_PAGE = '/advisors',
  ADMIN_INVESTOR_PAGE = '/investor',
  ADMIN_COMPANY_LIST = 'companies',
  ADMIN_INVESTORS_LIST = 'investors',
  ADMIN_ADVISORS_LIST = 'advisors',
  ADMIN_BUCKET_LIST = 'buckets',
  ADMIN_DOCUMENT_LIST = 'user-documents',
  ADMIN_INVESTMENT_LIST = 'admin-investments',
  ADMIN_ADVISES_LIST = 'admin-advises',
  ADMIN_SINGLE_BUCKET_PAGE = '/bucket',
  ADMIN_BUCKET_DOCUMENT_PAGE = '/bucket-document',
  INFO = 'information',
  DOCUMENTS = 'documents',
  WH_DOCUMENTS_PAGE = '/documents',
  SUBSCRIPTION = 'subscription',
  INVESTMENTS = 'investments',
  COACHINGS = 'coachings',
  DOCUSIGN = 'docusign',
}

export const RoutesWithoutNavBar = [
  AppRoutes.ONBOARDING,
  AppRoutes.ADMIN_COMPANY_PAGE,
  AppRoutes.ADMIN_BUCKET_DOCUMENT_PAGE,
  AppRoutes.ADMIN_SINGLE_BUCKET_PAGE,
  AppRoutes.ADMIN_DETAILED_COMPANY_PAGE,
];

export type TabDetails = Array<{
  name: string;
  url: AppRoutes;
  icon: JSX.Element;
}>;

export interface AdminConfigFormState {
  placementFees: string;
  partnershipFees: string;
  carriedInterestOnProfit: string;
  minimumInvestmentPool1: string;
  minimumInvestmentPool2: string;
  minimumInvestmentPool3: string;
  minInvReductionPool1: string;
  minInvReductionPool2: string;
  minInvReductionPool3: string;
  placementFeesReductionPool1: string;
  placementFeesReductionPool2: string;
  placementFeesReductionPool3: string;
  partnershipFeesReductionPool1: string;
  partnershipFeesReductionPool2: string;
  partnershipFeesReductionPool3: string;
  maxCreditPointApplicable: string;
  percentageReductionForCredits: string;
  carriedInterestOnProfitPool1: string;
  carriedInterestOnProfitPool2: string;
  carriedInterestOnProfitPool3: string;
}
