import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import './App.css';

import { isEmpty } from 'lodash';
import 'react-toastify/dist/ReactToastify.min.css';
import 'typeface-roboto';
import 'typeface-krona-one';
import { AppRoutes } from './types';
import LoginPage from './pages/login/Login';
import SignupPage from './pages/signup/Signup';
import LandingPage from './pages/landing/Landing';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { CommonConstants } from './constants';
import { useGetMeQuery } from './redux/services/authApi';
import LoadingScreen from './components/LoadingScreen';
import { setCurrentRouteName } from './redux/features/pageSlice';
import LoginSignupHeader from './components/LoginSignupHeader';

const ChooseSubscription = lazy(
  () => import('./components/ChooseSubscription'),
);
const UserDocuments = lazy(() => import('./pages/documents/UserDocuments'));
const DocusignResponse = lazy(() => import('./components/DocusignResponse'));
const TermsPage = lazy(() => import('./pages/whitehorse-legal/Terms'));
const VerifyPage = lazy(() => import('./pages/verify/Verify'));
const ResetPage = lazy(() => import('./pages/reset/Reset'));
const UnAuthorized = lazy(() => import('./components/UnAuthorized'));
const NotFoundPage = lazy(() => import('./components/NotFoundPage'));
const PageLayout = lazy(() => import('./components/PageLayout'));
const RequireUser = lazy(() => import('./components/RequireUser'));
// const HomePage = lazy(() => import('./pages/home.page'));
const CompanyDashboard = lazy(
  () => import('./pages/company-dashboard/CompanyDashboard'),
);
const InvestorDashboard = lazy(
  () => import('./pages/investors-dashboard/InvestorDashboard'),
);
const AdvisorDashboard = lazy(
  () => import('./pages/advisor-dashboard/AdvisorDashboard'),
);
const OnboardingPage = lazy(() => import('./pages/onboarding/Onboarding'));
// const InvestorsPage = lazy(() => import('./pages/investors/Investors'));
const InvestmentsPage = lazy(() => import('./pages/investments/Investments'));
const CoachingsPage = lazy(() => import('./pages/coachings/Coachings'));
const AdvisorsPage = lazy(() => import('./pages/advisors/Advisors'));
const ReferralPage = lazy(() => import('./pages/user-referral/UserReferral'));
const CompanyListForAdmin = lazy(
  () => import('./pages/admin/CompanyListForAdmin'),
);
const BucketListForAdmin = lazy(
  () => import('./pages/admin/bucket/BucketListForAdmin'),
);
const DocumentListForAdmin = lazy(
  () => import('./pages/admin/DocumentListForAdmin'),
);
const BucketDetailsForAdmin = lazy(
  () => import('./pages/admin/bucket/BucketDetailsForAdmin'),
);
const BucketDocumentsForAdmin = lazy(
  () => import('./pages/admin/bucket-documents/BucketDocumentsForAdmin'),
);
const DetailsForAdmin = lazy(() => import('./pages/admin/DetailsForAdmin'));
const InvestorListForAdmin = lazy(
  () => import('./pages/admin/InvestorListForAdmin'),
);
const InvestmentListForAdmin = lazy(
  () => import('./pages/admin/InvestmentListForAdmin'),
);
const CoachingsListForAdmin = lazy(
  () => import('./pages/admin/CoachingsListForAdmin'),
);
const AdvisorListForAdmin = lazy(
  () => import('./pages/admin/AdvisorListForAdmin'),
);
const CompanyPublic = lazy(
  () => import('./pages/company-details/CompanyPublic'),
);
const Payments = lazy(() => import('./pages/payments/Payments'));
const WhitehorseDocuments = lazy(
  () => import('./pages/wh-documents/WhitehorseDocuments'),
);

function App() {
  const location = useLocation();
  const currentRouteName = isEmpty(location.search)
    ? location.pathname
    : `${location.pathname}${location.search}`;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isUserAdmin = localStorage.getItem(CommonConstants.IS_ADMIN) === 'true';
  const accessToken = localStorage.getItem(CommonConstants.ACCESS_TOKEN);
  const isOtpValid = localStorage.getItem(CommonConstants.IS_OTP_VALID);
  const [isLoading, setIsLoading] = useState(
    Boolean(accessToken) && Boolean(isOtpValid),
  );

  const userJourneyData = useAppSelector((state) => state.user.journey);

  const { data: userData, isLoading: isUserDataLoading } = useGetMeQuery(
    { isAdmin: isUserAdmin },
    {
      skip: isEmpty(accessToken) || isEmpty(isOtpValid),
    },
  );

  useEffect(() => {
    if (
      currentRouteName &&
      currentRouteName !== '/' &&
      !currentRouteName.startsWith('/admin') &&
      !currentRouteName.startsWith('/payment') &&
      !currentRouteName.startsWith('/login') &&
      !currentRouteName.startsWith('/verify') &&
      !currentRouteName.startsWith('/reset') &&
      !currentRouteName.startsWith('/signup')
    ) {
      dispatch(setCurrentRouteName(currentRouteName));
    }
  }, [currentRouteName]);

  useEffect(() => {
    if (userData?.name) {
      const routeToNavigate =
        localStorage.getItem(CommonConstants.CURRENT_ROUTE_NAME) ||
        (isUserAdmin
          ? AppRoutes.ADMIN_DASHBORD
          : userData.endUserRole === 'COMPANY'
            ? AppRoutes.DASHBOARD
            : `${AppRoutes.DASHBOARD}/${AppRoutes.INVESTMENTS}`);
      navigate(routeToNavigate);
    }

    if (!isUserDataLoading) {
      setIsLoading(false);
    }
  }, [isUserDataLoading]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route
            path={`${AppRoutes.DETAILED_COMPANY_PAGE}/:identifier`}
            element={<RequireUser allowedRoles={['endUser']} />}
          >
            <Route element={<PageLayout />}>
              <Route index element={<CompanyPublic />} />
            </Route>
          </Route>
          <Route
            path={`${AppRoutes.WH_DOCUMENTS_PAGE}/:identifier`}
            element={<RequireUser allowedRoles={['endUser']} />}
          >
            <Route element={<PageLayout />}>
              <Route index element={<WhitehorseDocuments />} />
            </Route>
          </Route>
          <Route
            path={`${AppRoutes.PAYMENTS}`}
            element={<RequireUser allowedRoles={['endUser']} />}
          >
            <Route element={<PageLayout />}>
              <Route index element={<Payments />} />
            </Route>
          </Route>
          <Route
            path={AppRoutes.DASHBOARD}
            element={<RequireUser allowedRoles={['endUser']} />}
          >
            <Route element={<PageLayout />}>
              <Route
                index
                element={
                  userJourneyData?.profileCompletionStatus === 'COMPLETED' ||
                  userJourneyData?.userBypassed === true ? (
                    // <HomePage />
                    userData?.endUserRole === 'COMPANY' ? (
                      <CompanyDashboard email={userData.email} />
                    ) : userData?.endUserRole === 'INVESTOR' ? (
                      <InvestorDashboard email={userData.email} />
                    ) : userData?.endUserRole === 'ADVISOR_OR_COACH' ? (
                      <AdvisorDashboard email={userData.email} />
                    ) : (
                      <div />
                    )
                  ) : (
                    <OnboardingPage />
                  )
                }
              />
              <Route
                path={AppRoutes.INFO}
                element={
                  userData?.endUserRole === 'COMPANY' ? (
                    <CompanyDashboard email={userData.email} />
                  ) : userData?.endUserRole === 'INVESTOR' ? (
                    <InvestorDashboard email={userData.email} />
                  ) : userData?.endUserRole === 'ADVISOR_OR_COACH' ? (
                    <AdvisorDashboard email={userData.email} />
                  ) : (
                    <div />
                  )
                }
              />
              {/* <Route
                path={AppRoutes.INVESTORS}
                element={<InvestorsPage email={userData?.email || ''} />}
              /> */}
              <Route
                path={AppRoutes.SUBSCRIPTION}
                element={<ChooseSubscription email={userData?.email || ''} />}
              />
              <Route path={AppRoutes.DOCUSIGN} element={<DocusignResponse />} />
              <Route
                path={AppRoutes.DOCUMENTS}
                element={<UserDocuments email={userData?.email || ''} />}
              />
              <Route
                path={AppRoutes.INVESTMENTS}
                element={
                  userJourneyData?.profileCompletionStatus === 'COMPLETED' ||
                  userJourneyData?.userBypassed === true ? (
                    <InvestmentsPage
                      email={userData?.email || ''}
                      userRole={userData?.endUserRole || 'INVESTOR'}
                    />
                  ) : (
                    <OnboardingPage />
                  )
                }
              />
              <Route
                path={AppRoutes.COACHINGS}
                element={
                  userJourneyData?.profileCompletionStatus === 'COMPLETED' ||
                  userJourneyData?.userBypassed === true ? (
                    <CoachingsPage email={userData?.email || ''} />
                  ) : (
                    <OnboardingPage />
                  )
                }
              />
              <Route
                path={AppRoutes.ADMIN_ADVISORS_LIST}
                element={<AdvisorsPage email={userData?.email || ''} />}
              />
              <Route
                path={AppRoutes.REFERRAL_DASHBOARD}
                element={<ReferralPage email={userData?.email || ''} />}
              />
            </Route>
          </Route>
          <Route
            path={AppRoutes.ADMIN_DASHBORD}
            element={<RequireUser allowedRoles={['admin']} />}
          >
            <Route element={<PageLayout />}>
              {/* <Route index element={<HomePage isAdmin />} /> */}
              <Route index element={<CompanyListForAdmin />} />
              <Route
                path={AppRoutes.ADMIN_COMPANY_LIST}
                element={<CompanyListForAdmin />}
              />
              <Route
                path={AppRoutes.ADMIN_BUCKET_LIST}
                element={<BucketListForAdmin />}
              />
              <Route
                path={AppRoutes.ADMIN_DOCUMENT_LIST}
                element={<DocumentListForAdmin />}
              />
              <Route
                path={AppRoutes.ADMIN_INVESTORS_LIST}
                element={<InvestorListForAdmin />}
              />
              <Route
                path={AppRoutes.ADMIN_ADVISORS_LIST}
                element={<AdvisorListForAdmin />}
              />
              <Route
                path={AppRoutes.ADMIN_INVESTMENT_LIST}
                element={<InvestmentListForAdmin />}
              />
              <Route
                path={AppRoutes.ADMIN_ADVISES_LIST}
                element={<CoachingsListForAdmin />}
              />
            </Route>
          </Route>
          <Route
            path={AppRoutes.ADMIN_SINGLE_BUCKET_PAGE}
            element={<RequireUser allowedRoles={['admin']} />}
          >
            <Route element={<PageLayout />}>
              <Route index element={<BucketDetailsForAdmin isAdd />} />
            </Route>
          </Route>
          <Route
            path={`${AppRoutes.ADMIN_SINGLE_BUCKET_PAGE}/:identifier`}
            element={<RequireUser allowedRoles={['admin']} />}
          >
            <Route element={<PageLayout />}>
              <Route index element={<BucketDetailsForAdmin />} />
            </Route>
          </Route>
          <Route
            path={AppRoutes.ADMIN_BUCKET_DOCUMENT_PAGE}
            element={<RequireUser allowedRoles={['admin']} />}
          >
            <Route element={<PageLayout />}>
              <Route index element={<BucketDocumentsForAdmin isAdd />} />
            </Route>
          </Route>
          <Route
            path={`${AppRoutes.ADMIN_BUCKET_DOCUMENT_PAGE}/:identifier`}
            element={<RequireUser allowedRoles={['admin']} />}
          >
            <Route element={<PageLayout />}>
              <Route index element={<BucketDocumentsForAdmin />} />
            </Route>
          </Route>
          <Route
            path={`${AppRoutes.ADMIN_DETAILED_COMPANY_PAGE}/:identifier`}
            element={<RequireUser allowedRoles={['admin']} />}
          >
            <Route element={<PageLayout />}>
              <Route index element={<CompanyPublic isAdmin />} />
            </Route>
          </Route>
          <Route
            path={`${AppRoutes.ADMIN_COMPANY_PAGE}/:identifier`}
            element={<RequireUser allowedRoles={['admin']} />}
          >
            <Route element={<PageLayout />}>
              <Route index element={<DetailsForAdmin userRole="COMPANY" />} />
            </Route>
          </Route>
          <Route
            path={`${AppRoutes.ADMIN_INVESTOR_PAGE}/:identifier`}
            element={<RequireUser allowedRoles={['admin']} />}
          >
            <Route element={<PageLayout />}>
              <Route index element={<DetailsForAdmin userRole="INVESTOR" />} />
            </Route>
          </Route>
          <Route
            path={`${AppRoutes.ADMIN_ADVISORS_PAGE}/:identifier`}
            element={<RequireUser allowedRoles={['admin']} />}
          >
            <Route element={<PageLayout />}>
              <Route
                index
                element={<DetailsForAdmin userRole="ADVISOR_OR_COACH" />}
              />
            </Route>
          </Route>
          <Route
            path={AppRoutes.HOME}
            element={
              <div>
                <LoginSignupHeader />
                <LandingPage />
              </div>
            }
          />
          <Route
            path={AppRoutes.SIGNUP}
            element={
              <div>
                <LoginSignupHeader />
                <SignupPage />
              </div>
            }
          />
          <Route
            path={AppRoutes.LOGIN}
            element={
              <div>
                <LoginSignupHeader />
                <LoginPage />
              </div>
            }
          />
          <Route
            path={AppRoutes.VERIFY}
            element={
              <div>
                <LoginSignupHeader />
                <VerifyPage />
              </div>
            }
          />
          <Route
            path={AppRoutes.RESET}
            element={
              <div>
                <LoginSignupHeader />
                <ResetPage />
              </div>
            }
          />
          <Route
            path={AppRoutes.CLIENT_ADMIN_LOGIN}
            element={
              <div>
                <LoginSignupHeader />
                <LoginPage forAdmin />
              </div>
            }
          />
          <Route
            path={AppRoutes.TERMS}
            element={
              <div>
                <LoginSignupHeader />
                <TermsPage />
              </div>
            }
          />
          <Route
            path={AppRoutes.UNAUTHORIZED}
            element={<UnAuthorized isUserAdmin={isUserAdmin} />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
