import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { redirect } from 'react-router-dom';
import { AppRoutes } from '../../types';
import { logout } from '../features/userSlice';
import { CommonConstants, WhitehorseClientId } from '../../constants';

const baseUrl = process.env.SERVER_URL;

const headers = {
  'Content-Type': 'application/json',
  'x-client-pub-id': WhitehorseClientId,
  clientOrigin: process.env.FRONTEND_URL || '',
  Authorization: '',
};

const baseQuery = fetchBaseQuery({
  baseUrl,
  headers,
});

export const getJwtToken = () => {
  const token = localStorage.getItem(CommonConstants.ACCESS_TOKEN);
  return token;
};

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const token = getJwtToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`; // Set the token as the Bearer token
  }
  let result = await baseQuery(args, api, extraOptions);
  if (
    (result.error?.data as { message: string })?.message ===
    'You are not logged in'
  ) {
    const refreshResult = await baseQuery(
      { credentials: 'include', url: 'auth/refresh' },
      api,
      extraOptions,
    );

    if (refreshResult.data) {
      // Retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
      redirect(AppRoutes.HOME);
    }
  }

  return result;
};

export default customFetchBase;
