import {
  SharedServiceType,
  SharedUserJourneyType,
} from '@shared/api-responses';
import { DescriptionsProps } from 'antd';

export const WhitehorseClientId = '1234230310';
export class CommonConstants {
  static readonly ACCESS_TOKEN = 'access_token';

  static readonly IS_ADMIN = 'is_our_admin';

  static readonly CURRENT_ROUTE_NAME = 'route_name';

  static readonly IS_OTP_VALID = 'otp_valid';
}

export enum AdminActions {
  ACTIVATE_USER = 1,
  DEACTIVATE_USER = 2,
  COMPANY_LIVE = 3,
  COMPANY_PENDING = 4,
}

export const AiPriseConstants = {
  url: `${process.env.WHITEHORSE_CB_URL}/roles/company/stake-holders/verification`,
  clientId: WhitehorseClientId,
  templateId: 'd97febf1-0c7a-4fdb-a527-fe303d475107',
  mode: (process.env.NODE_ENV === 'production' ? 'PRODUCTION' : 'SANDBOX') as
    | 'PRODUCTION'
    | 'SANDBOX',
};

export enum CompanySections {
  COMPANY_DETAILS = 1,
  FOUNDER_DETAILS,
  BOARD_MEMBER_DETAILS,
  ADVISOR_DETAILS,
  INVESTOR_DETAILS,
  ADDON_SERVICES,
}

export enum InvestorSections {
  PERSONAL_DETAILS = 1,
  GENERAL_DETAILS,
  // INVESTMENT_DETAILS,
  FUNDING_DETAILS,
  ADVISOR_DETAILS,
  ACCREDITATION_DETAILS,
  KYC_DETAILS,
  // ACQUIRER_DETAILS,
  ADDON_SERVICES,
}

export enum AdvisorSections {
  PERSONAL_DETAILS = 1,
  GENERAL_DETAILS,
  ADDITIONAL_DETAILS,
  FUNDING_DETAILS,
  ACCREDITATION_DETAILS,
  KYC_DETAILS,
  ADDON_SERVICES,
}

export const DashboardLabelStyles = {
  fontWeight: 500,
  color: '#000',
  lineHeight: '16px',
};

export const DashboardContentStyles = {
  color: '#555770',
  lineHeight: '16px',
};

export const AvatarSizes = { xs: 32, sm: 48, md: 48, lg: 64, xl: 80, xxl: 80 };
export const DashboardColumnSizes = {
  xl: 3,
  lg: 3,
  md: 3,
  sm: 2,
  xs: 1,
};

export const CustomDescriptionProps: DescriptionsProps = {
  size: 'default',
  layout: 'vertical',
  column: DashboardColumnSizes,
  contentStyle: DashboardContentStyles,
  labelStyle: DashboardLabelStyles,
};

export enum SectionNames {
  COMPANY_ADMIN_CONFIGS = 'Company Admin Configurations',
  COMPANY_DETAILS = 'Company Details',
  FOUNDER_DETAILS = 'Founder Details',
  BOARD_DETAILS = 'Board Member Details',
  ADVISOR_DETAILS = 'Advisor Details',
  INVESTOR_DETAILS = 'Investor Details',
  AOS_DETAILS = 'Premium - Add on Services',
  PERSONAL_DETAILS = 'Personal Details',
  GENERAL_DETAILS = 'General Details',
  INVESTMENT_DETAILS = 'Investment Details',
  FUNDING_DETAILS = 'Funding Details - Optional',
  ACQUIRER_DETAILS = 'Acquirer Details',
  MY_ADVISOR_DETAILS = 'My Advisor Details',
  COMPANY_DOCUMENTS = 'Company Documents',
  COMPANY_TERMS = 'Company Terms',
  ACCREDITATION_DETAILS = 'Accreditation Details',
  KYC_DETAILS = 'KYC Details',
}

type EndUserRoleMap = {
  [K in SharedUserJourneyType['endUserRole']]: SectionNames[];
};

export const SectionHeaders: EndUserRoleMap = {
  COMPANY: [
    SectionNames.COMPANY_DETAILS,
    SectionNames.FOUNDER_DETAILS,
    SectionNames.BOARD_DETAILS,
    SectionNames.ADVISOR_DETAILS,
    SectionNames.INVESTOR_DETAILS,
    SectionNames.AOS_DETAILS,
    SectionNames.COMPANY_DOCUMENTS,
    SectionNames.COMPANY_TERMS,
  ],
  INVESTOR: [
    SectionNames.PERSONAL_DETAILS,
    SectionNames.GENERAL_DETAILS,
    // SectionNames.INVESTMENT_DETAILS,
    SectionNames.FUNDING_DETAILS,
    SectionNames.MY_ADVISOR_DETAILS,
    SectionNames.ACCREDITATION_DETAILS,
    SectionNames.KYC_DETAILS,
    // SectionNames.ACQUIRER_DETAILS,
    // SectionNames.AOS_DETAILS,
  ],
  ADVISOR_OR_COACH: [
    SectionNames.PERSONAL_DETAILS,
    SectionNames.GENERAL_DETAILS,
    SectionNames.MY_ADVISOR_DETAILS,
    SectionNames.FUNDING_DETAILS,
    SectionNames.ACCREDITATION_DETAILS,
    SectionNames.KYC_DETAILS,
    // SectionNames.AOS_DETAILS,
  ],
  JOB_SEEKER: [],
  VENDOR: [],
  WHITEHORSE_ACCELERATOR: [],
};

export const LocationEnum = {
  INDIA: 1,
  USA: 2,
  UAE: 3,
  SINGAPORE: 4,
  MALAYSIA: 5,
  CANADA: 6,
} as const;

export const CompanyPremiumServices: SharedServiceType[] = [
  // 'PITCH_DECK',
  // 'ID_KYC_AND_AML',
  // 'CAP_TABLE',
  'COMPANY_VALUATION',
  'INSURANCE',
  'LEGAL_HELP',
  'US_INCORPORATION',
  // 'FOUNDER_BACKGROUND_CHECK',
  'FINANCIAL_AUDIT',
  // 'WHITEHORSE_SUBSCRIPTION_PLAN',
];

export const getPremiumServiceName = (type: SharedServiceType) => {
  switch (type) {
    case 'PITCH_DECK':
      return 'Link to Data Room*';
    case 'ID_KYC_AND_AML':
      return 'KYC & AML Verification';
    case 'CAP_TABLE':
      return 'Cap Table Validation';
    case 'COMPANY_VALUATION':
      return 'Company Valuation';
    case 'INSURANCE':
      return 'Insurance';
    case 'LEGAL_HELP':
      return 'Legal Due Diligence';
    case 'US_INCORPORATION':
      return 'US Incorporation';
    case 'FOUNDER_BACKGROUND_CHECK':
      return 'Founder Background Check';
    case 'FINANCIAL_AUDIT':
      return 'Financial Audit';
    case 'WHITEHORSE_SUBSCRIPTION_PLAN':
      return 'Whitehorse Subscription';
    default:
      return '';
  }
};

export const API_UPLOAD_URL = `${process.env.SERVER_URL}/client-users/upload`;

export const getLocationFromLocationId = (locationId: number) => {
  switch (locationId) {
    case LocationEnum.INDIA:
      return 'India';
    case LocationEnum.USA:
      return 'USA';
    case LocationEnum.UAE:
      return 'UAE';
    case LocationEnum.SINGAPORE:
      return 'Singapore';
    case LocationEnum.MALAYSIA:
      return 'Malaysia';
    case LocationEnum.CANADA:
      return 'Canada';
    default:
      return '';
  }
};

export const getAccreditedQuestionForLocation = (locationId: number) => {
  switch (locationId) {
    case LocationEnum.SINGAPORE:
      return {
        annualIncomeCriteria:
          'I have a net personal asset exceeding SGD 2 million, or its equivalent in a foreign currency, excluding my primary residence.',
        netWorthCriteria:
          'I have an annual income of at least SGD 300,000, or its equivalent in a foreign currency, for the past 12 months.',
        representTrustCriteria:
          'I represent a corporation with net assets exceeding SGD 10 million, or its equivalent in a foreign currency.',
      };
    default:
      return {
        annualIncomeCriteria:
          'I have an annual individual income of at least USD 200,000 (USD 300,000 for joint income with a spouse) for the past two years and current year.',
        netWorthCriteria:
          'I have a net worth (individually or jointly with a spouse) exceeding USD 1 million, excluding the value of my primary residence.',
        representTrustCriteria:
          'I represent a trust or entity with assets exceeding USD 5 million.',
      };
  }
};

export const CompanyDocumentTypes = {
  CAP_TABLE: 1,
  COMPANY_OVERVIEW: 2,
  COMPANY_SUMMARY: 3,
  CROWD_NOTE: 4,
  OFFERING_STATEMENT: 5,
  FORM_C: 6,
  SUBSCRIPTION_AGREEMENT: 7,
} as const;

// When changing here, have to change in frontend/src/constants/index.ts
export const UserDocumentTypes = {
  SUBSCRIPTION_AGREEMENT: 1,
  KYC_INFORMATION: 2,
  PAYMENT_RECEIPT: 3,
} as const;

export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'code',
});

export const IndianRupees = new Intl.NumberFormat('en-in', {
  style: 'currency',
  currency: 'INR',
  currencyDisplay: 'code',
});

export const INVESTMENT_VEHICLE = {
  SAFE_NOTE: 1,
  CONVERTIBLE: 2,
  PRICED_ROUND: 3,
  OTHER: 4,
} as const;
