import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CgProfile } from 'react-icons/cg';
import { Button, notification } from 'antd';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { startCase } from 'lodash';

import { SharedEndUserRoleType } from '@shared/api-inputs';

import {
  useCheckUserEmailMutation,
  useLoginUserMutation,
  useForgotPasswordMutation,
  useResendVerificationMutation,
  useGenerateOtpMutation,
  useVerifyOtpMutation,
} from '../../redux/services/authApi';

import { AppRoutes } from '../../types';
import { useAppDispatch } from '../../redux/hooks';
import { setActiveRoute } from '../../redux/features/pageSlice';
import CustomFormInput from '../../components/form/CustomFormInput';
import { CommonConstants } from '../../constants';
import LoginSignupHero from '../../components/LoginSignupHero';
import {
  ArgsProps,
  NotificationInstance,
} from 'antd/es/notification/interface';

export default function LoginPage({ forAdmin = false }) {
  const [api, contextHolder] = notification.useNotification();
  const [notificationData, setNotificationData] = useState<{
    type: keyof NotificationInstance;
    content: ArgsProps | undefined;
  }>({
    type: 'success',
    content: undefined,
  });

  useEffect(() => {
    if (notificationData.content) {
      api[notificationData.type](notificationData.content as any);
    }
  }, [notificationData]);
  const navigate = useNavigate();
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [resendButtonDisabled, setResendButtonDisabled] = useState(true);
  const [userRole, setUserRole] = useState<SharedEndUserRoleType>();
  const [userEmail, setUserEmail] = useState('');
  const [userSecret, setUserSecret] = useState('');
  const [userOtp, setUserOtp] = useState('');
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState(
    'Unable to find your account.',
  );
  const [passwordErrMsg, setPasswordErrMsg] = useState(
    'Invalid Credentials, Please try again.',
  );
  const dispatch = useAppDispatch();

  const [checkEmail, { isLoading: isCheckingEmail, isError: checkEmailError }] =
    useCheckUserEmailMutation();

  const [loginUser, { isLoading: isValidatingCreds, isError: isLoginError }] =
    useLoginUserMutation();

  const [generateOtp] = useGenerateOtpMutation();

  const [
    verifyOtp,
    { isError: otpValidationError, isLoading: isValidatingOtp },
  ] = useVerifyOtpMutation();

  const [resendVerification, { isLoading: isResendingVerification }] =
    useResendVerificationMutation();
  const [forgotPassword, { isLoading: isForgotPasswordLoading }] =
    useForgotPasswordMutation();

  const handleResendEmail = async () => {
    const resendResult = await resendVerification({ email: userEmail });

    if ('data' in resendResult && resendResult.data.verificationEmailSent) {
      setNotificationData({
        type: 'success',
        content: {
          message: 'Verification Email sent, Please check your inbox.',
          placement: 'topRight',
          duration: 6,
        },
      });
    }
    setShowVerifyButton(false);
    setPasswordErrMsg('Please check your email for verification link.');
  };

  const handleForgotPassword = async () => {
    const forgotPasswordResult = await forgotPassword({ email: userEmail });
    if ('data' in forgotPasswordResult && forgotPasswordResult.data.emailSent) {
      setNotificationData({
        type: 'success',
        content: {
          message:
            'Reset password email has been sent, Please check your inbox.',
          placement: 'topRight',
          duration: 6,
        },
      });
    }
  };

  const handleOtpGeneration = async () => {
    setResendButtonDisabled(true);
    setEmailErrMsg('');
    setEmailErrMsg('');
    setUserOtp('');
    const otpGenerationResponse = await generateOtp({
      email: userEmail,
    });

    if (
      'error' in otpGenerationResponse &&
      'data' in otpGenerationResponse.error
    ) {
      setNotificationData({
        type: 'error',
        content: {
          message: 'Unable to generate OTP. Please try after sometime.',
          placement: 'topRight',
          duration: 6,
        },
      });
    } else if (
      'data' in otpGenerationResponse &&
      otpGenerationResponse.data.isOtpGenerated
    ) {
      setNotificationData({
        type: 'success',
        content: {
          message:
            'OTP has been sent to your email. You can resend after 3 minutes, if needed.',
          placement: 'topRight',
          duration: 6,
        },
      });
      setShowOtpSection(true);
    }
  };

  const handleOtpSubmission = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const inputObject = {
      email: userEmail,
      otpCode: userOtp,
    };
    const response = await verifyOtp(inputObject);
    if ('error' in response && 'data' in response.error) {
      const errorData = response.error.data as {
        message: string;
        statusCode: number;
      };
      if (errorData.statusCode === 403) {
        setEmailErrMsg(startCase(errorData.message));
      }
      setResendButtonDisabled(false);
    } else if ('data' in response && response.data.isOtpValid) {
      setIsOtpValid(true);
    }
  };

  const handleEmailSubmission = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const inputObject = {
      email: userEmail,
      forAdmin,
    };
    localStorage.removeItem(CommonConstants.ACCESS_TOKEN);
    localStorage.removeItem(CommonConstants.IS_ADMIN);
    const response = await checkEmail(inputObject);
    if ('error' in response && 'data' in response.error) {
      const errorData = response.error.data as {
        message: string;
        statusCode: number;
      };
      if (errorData.statusCode === 403) {
        setEmailErrMsg(startCase(errorData.message));
      }
    } else if ('data' in response && response.data.isEmailValid) {
      setUserRole(response.data.userRole);
      setIsEmailValid(true);
    }
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const inputObject = {
      email: userEmail,
      secret: userSecret,
      forAdmin,
    };
    const res = await loginUser(inputObject);
    if ('error' in res && 'data' in res.error) {
      const errorData = res.error.data as {
        message: string;
        statusCode: number;
      };
      if (errorData.statusCode === 401) {
        setPasswordErrMsg('Invalid Credentails. Please try again.');
      } else if (
        errorData.statusCode === 403 &&
        errorData.message.includes('INACTIVE')
      ) {
        setPasswordErrMsg(
          'Your account is deactivated. Please reach out to us.',
        );
      } else if (
        errorData.statusCode === 403 &&
        errorData.message.includes('USER_NOT_VERIFIED')
      ) {
        setShowVerifyButton(true);
        setPasswordErrMsg('Email is not verified. Please verify.');
      }
    } else {
      handleOtpGeneration();
    }
  };

  const handleBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setUserEmail('');
    setIsEmailValid(false);
    setPasswordErrMsg('');
    setEmailErrMsg('');
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (resendButtonDisabled) {
      // Disable the "Resend OTP" button
      timeoutId = setTimeout(() => {
        setResendButtonDisabled(false);
      }, 60000); // 60 seconds
    }

    // Clear the timeout to prevent memory leaks when the component unmounts or when the dependency changes
    return () => clearTimeout(timeoutId);
  }, [resendButtonDisabled]);

  useEffect(() => {
    if (isOtpValid) {
      navigate(
        forAdmin
          ? AppRoutes.ADMIN_DASHBORD
          : userRole === 'COMPANY'
            ? AppRoutes.DASHBOARD
            : userRole === 'INVESTOR'
              ? `${AppRoutes.DASHBOARD}/${AppRoutes.INVESTMENTS}`
              : `${AppRoutes.DASHBOARD}/${AppRoutes.COACHINGS}`,
      );
    }
  }, [isOtpValid]);

  useEffect(() => {
    dispatch(setActiveRoute(AppRoutes.LOGIN));
  }, []);

  return (
    <div className="flex min-h-screen min-w-full">
      {contextHolder}
      <div className="min-h-screen w-full xl:w-1/2">
        <div className="login-signup-container">
          <h1 className="py-6 text-2xl font-bold md:text-4xl">
            {forAdmin ? 'Sign In - Admin' : 'Sign In'}
          </h1>

          {showOtpSection ? (
            <form onSubmit={handleOtpSubmission}>
              <CustomFormInput
                fieldError={false}
                inputName="otp"
                labelName="Verification Code in Email"
                id="otp"
                type="text"
                placeHolder="OTP in Email"
                value={userOtp}
                isOptional={false}
                onValueChange={setUserOtp}
                showErrorMsg={otpValidationError}
                errMsg={emailErrMsg}
              />
              <div className="flex justify-between py-8">
                {/* <button
                  className="button-secondary flex items-center px-4"
                  type="button"
                  onClick={() => setShowOtpSection(false)}
                >
                  <div className="text-xl mr-1">
                    <AiOutlineArrowLeft />
                  </div>
                  <div className="px-1">Back</div>
                </button> */}
                <Button
                  onClick={handleOtpGeneration}
                  disabled={resendButtonDisabled}
                  className="antd-button-primary-v1"
                >
                  <div className="px-1">Resend OTP?</div>
                </Button>
                <button
                  className="button-primary flex items-center justify-end px-4"
                  type="submit"
                  disabled={isValidatingCreds}
                >
                  <div className="px-1">
                    {isValidatingOtp ? 'Validating OTP...' : 'Validate'}
                  </div>
                  <div className="text-xl mr-4">
                    <AiOutlineArrowRight />
                  </div>
                </button>
              </div>
            </form>
          ) : (
            <div>
              {!isEmailValid ? (
                <form onSubmit={handleEmailSubmission}>
                  <CustomFormInput
                    fieldError={false}
                    inputName="Email"
                    labelName="Email"
                    id="email"
                    type="email"
                    placeHolder="Enter your email"
                    value={userEmail}
                    isOptional={false}
                    onValueChange={setUserEmail}
                    showErrorMsg={checkEmailError}
                    errMsg={emailErrMsg}
                  />
                  <div className="flex justify-between pt-8">
                    <button
                      className="button-primary flex-1 flex justify-between items-center"
                      type="submit"
                      disabled={isCheckingEmail}
                    >
                      <div className="px-1">
                        {isCheckingEmail ? 'Validating Email...' : 'Next'}
                      </div>
                      <div className="text-xl mr-4">
                        <AiOutlineArrowRight />
                      </div>
                    </button>
                  </div>
                  <div className="flex pt-4">
                    <button
                      className="button-secondary flex-1 flex justify-between items-center"
                      type="button"
                      onClick={() => {
                        navigate(AppRoutes.SIGNUP);
                      }}
                    >
                      <div className="px-1">Not a member yet? Sign Up</div>
                      <div className="text-xl mr-4">
                        <AiOutlineArrowRight />
                      </div>
                    </button>
                  </div>
                </form>
              ) : (
                <div>
                  <form onSubmit={handleLogin}>
                    <div className="py-4 text-center">
                      <div className="flex-center rounded-lg py-2 text-xl text-primary-v1 ">
                        <CgProfile />
                        <span className="pl-2 font-medium">{userEmail}</span>
                      </div>
                    </div>
                    <CustomFormInput
                      fieldError={false}
                      inputName="password"
                      labelName="Password"
                      id="password"
                      type="password"
                      placeHolder="Enter your password"
                      value={userSecret}
                      isOptional={false}
                      onValueChange={setUserSecret}
                      showErrorMsg={isLoginError}
                      errMsg={passwordErrMsg}
                    />
                    <div className="flex justify-between py-8">
                      <button
                        className="button-secondary flex items-center px-4"
                        type="button"
                        onClick={(e) => handleBack(e)}
                      >
                        <div className="text-xl mr-1">
                          <AiOutlineArrowLeft />
                        </div>
                        <div className="px-1">Back</div>
                      </button>
                      <button
                        className="button-primary flex items-center justify-end px-4"
                        type="submit"
                        disabled={isValidatingCreds}
                      >
                        <div className="px-1">
                          {isValidatingCreds
                            ? 'Validating Credentials...'
                            : 'Login'}
                        </div>
                        <div className="text-xl ml-1">
                          <AiOutlineArrowRight />
                        </div>
                      </button>
                    </div>
                  </form>
                  <div className="flex justify-between">
                    {showVerifyButton ? (
                      <Button
                        onClick={handleResendEmail}
                        disabled={isResendingVerification}
                        className="antd-button-secondary-v1"
                      >
                        {isResendingVerification
                          ? 'Sending Email...'
                          : 'Resend Verification Email'}
                      </Button>
                    ) : (
                      <div />
                    )}
                    <Button
                      onClick={handleForgotPassword}
                      disabled={isForgotPasswordLoading}
                      className="antd-button-primary-v1"
                    >
                      {isForgotPasswordLoading
                        ? 'Sending Email...'
                        : 'Forgot Password ?'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <LoginSignupHero />
    </div>
  );
}
